import DocViewer, {
  DocRenderer,
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer";
import { Modal, Skeleton, Tooltip } from "antd";
import {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { FileDto } from "./Types";

interface FileViewerProps {
  file: FileDto;
  visible: boolean;
  viewerStyle?: CSSProperties;
  zIndex?: number;
  onCancel: () => void;
}

const FileViewer: FunctionComponent<FileViewerProps> = ({
  file,
  visible,
  viewerStyle,
  zIndex,
  onCancel,
}) => {
  const [showDocViewerModal, setShowDocViewerModal] = useState(false);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showPlayerTooltip, setShowPlayerTooltip] = useState(false);

  useEffect(() => {
    if (file?.fileType == "audio" || file?.fileType == "video")
      setShowPlayerModal(true);
    else if (file?.fileType != "image") setShowDocViewerModal(true);
  }, [visible]);

  const MyNoRenderer = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          width: "fit-content",
          height: "fit-content",
        }}
      >
        Impossibile aprire i file con la seguente estensione: {file?.extension}
      </div>
    );
  };

  return (
    <div className="file-viewer" style={{ display: visible ? "" : "none" }}>
      <Modal
        bodyStyle={{
          height: "fit-content",
          overflow: "hidden",
          display: "flex",
          placeItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
        width={"fit-content"}
        zIndex={zIndex}
        visible={visible && showDocViewerModal}
        centered
        closable
        destroyOnClose
        onCancel={() => {
          onCancel();
          setShowDocViewerModal(false);
        }}
        footer={null}
      >
        <MyDocViewer
          url={file.uri}
          style={viewerStyle ?? { height: 800, width: 800 }}
        />
        {/* <DocViewer
          documents={[{ uri: file.uri, fileName: file.name }]}
          pluginRenderers={DocViewerRenderers}
          config={{ noRenderer: { overrideComponent: MyNoRenderer } }}
          style={viewerStyle ?? { height: 800, width: 800 }}
        /> */}
      </Modal>

      <Modal
        bodyStyle={{
          height: "fit-content",
          display: "flex",
          placeItems: "center",
          alignItems: "flex-start",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: 20,
        }}
        width={"fit-content"}
        zIndex={zIndex}
        visible={visible && showPlayerModal}
        centered
        closable
        destroyOnClose
        onCancel={() => {
          onCancel();
          setShowPlayerModal(false);
        }}
        footer={null}
      >
        <Tooltip title={file.name} visible={showPlayerTooltip}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
              marginLeft: 10,
              maxWidth: 630,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onMouseEnter={(e) => {
              if (
                e.currentTarget.clientWidth >=
                Number(
                  e.currentTarget.style.maxWidth.substring(
                    0,
                    e.currentTarget.style.maxWidth.length - 2
                  )
                )
              )
                setShowPlayerTooltip(true);
            }}
            onMouseLeave={() => setShowPlayerTooltip(false)}
          >
            {file.name}
          </div>
        </Tooltip>
        <ReactPlayer
          url={file.uri}
          controls
          height={file.fileType == "audio" ? 60 : "100%"}
        />
      </Modal>
    </div>
  );
};

const MyDocViewer = ({ url, style }: { url: string; style: CSSProperties }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  var interval: NodeJS.Timeout | undefined;

  const generateUrl = () => {
    return `https://docs.google.com/gview?embedded=true&url=${url}&nonce=${Date.now()}`;
  };
  const pdfUrl = generateUrl();

  const [loaded, setLoaded] = useState(false);

  const clearCheckingInterval = () => {
    clearInterval(interval);
  };

  const onIframeLoaded = () => {
    clearCheckingInterval();
    setLoaded(true);
  };

  useEffect(() => {
    interval = setInterval(() => {
      try {
        // google docs page is blank (204), hence we need to reload the iframe.
        if (iframeRef.current?.contentWindow?.document.body.innerHTML === "") {
          iframeRef.current.src = generateUrl();
        }
      } catch (e) {
        // google docs page is being loaded, but will throw CORS error.
        // it mean that the page won't be blank and we can remove the checking interval.
        onIframeLoaded();
      }
    }, 4000); // 4000ms is reasonable time to load 2MB document

    return clearCheckingInterval;
  }, []);

  return (
    <div style={{ ...style, position: "relative" }}>
      <iframe
        style={style}
        ref={iframeRef}
        onLoad={onIframeLoaded}
        src={pdfUrl}
      />
      {!loaded && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            padding: 50,
          }}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      )}
    </div>
  );
};

export default FileViewer;
